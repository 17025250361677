@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/kanal-reform/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/kanal-reform/src/scss/utility/_mixin.scss";


main{
     overflow: hidden;
}
.gr-title{
    text-align: center;
    margin-bottom: 56px;
    @include SP{
        margin-bottom: 43px;
    }
    .en{
        @include font(16,20px,0.16em,500);
        @include inter;
        color: #7C7C7C;
        display: inline-block;
        margin-bottom: 20px;
        @include SP{
            @include font(14,17px,0.16em,500);
            margin-bottom: 14px;
        }
    }
    .ttl{
        @include font(23,1.6,0.04em,bold);
        margin-bottom: 12px;
        @include SP{
            @include font-size(18);
            margin-bottom: 6px; 
            margin-top: 7px;
        }
    }
    .jp{
        @include font(38,1,0.04em,bold);
        color: #2B2626;
        @include SP{
            @include font(28,1,0.04em,bold);
        }
    }
    .jp2{
        @include font(42,1,0.04em,bold);
        color: #2B2626;
        @include SP{
            font-size: 34px;
        }
    }
}

.mv{
    width: 100%; 
    position: relative;
    z-index: 2;
    margin-bottom: 60px;
    @include SP{
        margin-bottom:  0px;
    }
    &__ins{
        width: 95%;
        position: relative;
        margin-left: 5%;
        border-radius: 40px 0 0  0;
        overflow: hidden;
        height: 610px; 
        @include SP{
            width: 100%;
            margin-left: auto;
            border-radius: 0;
            height: calc(100vh - 112px);
        }
    }
    span.thumb{
        position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
        @include PC{
             background-position: center top -120px;
        }
        @include max-screen(1400px) {
            background-position: center center;
        }
        @include SP{
            background-image: url(../../img/top/main_sp.jpg) !important ;
         
        }
    }
    img{
        width: 100%;
    }
    .bnr1,.bnr2{
        position: absolute;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    .bnr1{ 
        top:14%; left: -2%;
        
        img{
            @include PC{
                width: 580px; height: 460px;
            } 
        }
        @include SP{ 
            text-align: center;
            width: 100%; left: 0;
            top: 3%;
            img{
                width: 350px; height: auto;
                max-width: 100%;
            }
            
        }
        @include IP5{
            img{
                max-width: 240px;
            }
        }
    }
    .bnr2{
        width: 260px; height: 157px;
        top: 5%; right: 4%;
        @include SP{
            width: 100%; left: 0; text-align: right;
            top: 42%;  
            img{
                width: 150px; height: auto;
                max-width: 100%;
                margin-right: 16px;
            }
        }
        @include IP5{
            top: 44%;
            text-align: right; padding-right: 30px;
            img{
                 
            }
        }
    }
    .txt-scroll {  
        line-height: 1;  
        position: absolute;
        display: inline-block;
        width: 36px;
        height: 36px;
        white-space: nowrap;
        bottom: 66px;
        left: 20px;
        display: inline-block;
        font-weight: 500;
        z-index: 10 ; 
        z-index: 3;
        @include SP{  
          padding-top: 0;
          height: 50px;
        }
        span {
          position: relative;
          display: inline-block;
          width: 100%;  
          @include font(12);
          letter-spacing: 0.08em;
          color: #212D4D;
          @include inter;
          @include transform_c(rotate(-90deg));
          @include SP{ 
          }
        } 
        em {
          content: "";
          position: absolute;
          width: 10px;
          height: 30px;
          background: #212D4D;
          bottom: -34px;
          right: 14px;
          background: url(../../img/top/scroll.svg) center center / cover no-repeat;
          -webkit-animation: scrollBorder 3s infinite ease-out;
          animation: scrollBorder 3s infinite ease-out;
          @include SP{
            
          }
        }
        @keyframes scrollBorder {
            0% {
                -webkit-transform: scaleY(1);transform: scaleY(1); -webkit-transform-origin:  0 100% ;transform-origin: 0 100%;
            }
            15% {
                -webkit-transform: scaleY(0);transform: scaleY(0); -webkit-transform-origin: 0 100% ;transform-origin: 0 100%;
            }
            50% {
                -webkit-transform: scaleY(0);transform: scaleY(0); -webkit-transform-origin: 0 0;transform-origin: 0 0;
            }
            70% {
                -webkit-transform: scaleY(1);transform: scaleY(1); -webkit-transform-origin: 0 0;transform-origin: 0 0;
            }
            100% {
                -webkit-transform: scaleY(1);transform: scaleY(1); -webkit-transform-origin: 0 100% ;transform-origin: 0 100%
            }
        }
        @-webkit-keyframes scrollBorder {
            0% {
                -webkit-transform: scaleY(1);transform: scaleY(1); -webkit-transform-origin:  0 100% ;transform-origin: 0 100%;
            }
            15% {
                -webkit-transform: scaleY(0);transform: scaleY(0); -webkit-transform-origin: 0 100% ;transform-origin: 0 100%;
            }
            50% {
                -webkit-transform: scaleY(0);transform: scaleY(0); -webkit-transform-origin: 0 0;transform-origin: 0 0;
            }
            70% {
                -webkit-transform: scaleY(1);transform: scaleY(1); -webkit-transform-origin: 0 0;transform-origin: 0 0;
            }
            100% {
                -webkit-transform: scaleY(1);transform: scaleY(1); -webkit-transform-origin: 0 100% ;transform-origin: 0 100%
            }
        }
    }
}
.top.pace-done{
    .mv .bnr1,.mv .bnr2{
        opacity: 1;
    }
}

.gr1{
    background: #FAF4EF;
    position: relative;
    @include SP{
        padding-top: 30px;
    }
    &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: calc(100% + 467px);
        top: -467px;
        z-index: 1;
        background: #FAF4EF;
        @include SP{
            display: none;
        }
    }
    .post-news{
        padding: 0 14px; 
        text-align: center;
        position: relative;
        z-index: 2;
        @include SP{
            padding-top: 18px;
        }
        a{
            max-width: 880px;
            position: relative;
            margin: 0 auto;
            border: 1px solid #E15B29;
            border-radius: 5px;;
            display: inline-block;
            width: 100%;
            min-height: 80px;
            text-align: left;
            padding : 20px 24px 10px 240px; 
            background: #fff;
            z-index: 1;
            @include SP{
                padding: 12px 10px 6px 115px;
                min-height: 70px;
            }
            &:after,&::before{
                content: "";
                position: absolute; 
            }
            &::before{
                width: 170px;
                height: 100%;
                background: url(../../img/top/gr1_bg.svg) repeat      ;
                background-position:  left top 10px;
                top: 0; left: 0;
                z-index: 0;
                @include SP{
                    width: 84px;
                }
            }
            &:after{
                width: 181px; height: 96px;
                bottom: 0; left: 50px;
                background: url(../../img/top/gr_ico_01.svg) no-repeat center center / 100% 100%  ; 
                @include SP{
                    width: 133px;
                    height: 67px;
                    left: -10px;
                }
            }
            span{
                @include font(24,1.6,0.02em,bold);
                color: #E15B29;
                text-align: left;
                width: 100%;
                display: inline-block;
                position: relative;
                padding-right: 20px;
                @include SP{
                    @include font(16,22px,0.02em,bold);
                }
                &:after{
                    content: "";
                    position: absolute;  right: 0; top: 50%; transform: translateY(-50%);;
                    background: url(../../img/common/icon/ico_arr1.svg) repeat center center  ; 
                    width: 15px;
                    height: 9px;
                }
            }
             
        }
    }
    .event{
        position: relative; z-index: 2;
        padding: 55px 0 100px;
        @include max-screen(1270px) {
            overflow: hidden;
        }
        @include SP{
            padding: 40px 0  55px;
        }
        &:after{
            content: "";
            position: absolute;
            height: 63%;
            width: calc(50% - 75px);
            background: url(../../img/top/gr1_bg.svg)  repeat   ;  
            right: 0;
            bottom: 0;
            z-index: -1;
            @include SP{
                display: none;
            }
        }
        &__cont{
            text-align: center;
            padding-bottom: 100px;
            @include SP{
                padding-bottom: 92px;
            }
        }
        &__list{
            max-width: 1126px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            
            .item{
                width: 33.333%;
                padding: 0 13px;
                @include SP{
                    width: 100%;
                    margin-bottom: 20px;
                }
                a{ 
                    display: inline-block;
                    width: 100%;
                    background: #fff;
                    border-radius: 0 0 5px 5px;
                    text-align: left;
                    padding: 0 0 15px;
                    height: 100%;
                    .zimg_frame{
                        border-radius: 5px 5px 0 0;
                        margin-bottom: 20px;
                        @include SP{
                            margin-bottom: 16px;
                        }
                    }
                    .im{
                        @include aspect-ratio(349px,230px); 
                        overflow: hidden;
                        span{
                            position: absolute;
                            width: 100%; height: 100%; top: 0; left: 0;
                             
                        }
                    }
                    .cat{
                        padding: 0 20px;
                        font-size: 0;
                        span{
                            @include font(13,22px,0.04em,bold);
                            color: #fff;
                            background: #7c7c7c;
                            border-radius: 3px ;
                            padding: 1px 5px 2px;
                            margin-right: 5px;
                            display: inline-block;
                            margin-bottom: 5px;
                            @include SP() {
                                padding: 1px 5px 2px;
                            }
                        }
                    }
                    .ttl{
                        padding: 0 20px;
                        @include font(18,1.4,0.02em,bold);
                        color: #2B2626;
                        margin-bottom: 2px;
                    }
                    .schedule{
                        @include font(14,1.6,0.02em,bold); 
                        padding: 0 20px 0 50px;
                        position: relative;
                        display: inline-block;
                        &:before{
                            content: "";
                            position: absolute;
                            width: 15px; height: 15px;
                            background: url(../../img/top/event_ico.svg) no-repeat center center / 100% 100%  ;
                            left: 23px; top: 4px;
                        }
                        span {
                            display: inline-block;
                            &:last-child {
                                em {
                                    display: none;
                                }
                            }
                        }
                    }
                    .date{
                        @include font(12,1.6 ,0.02em,bold);
                        padding: 0 20px 0 50px;
                    }
                }
            }
        }
        &__gr{
            display: inline-flex !important;
            flex-wrap: wrap;
            width: 100%;
        }
        .slick-slide{
             
        }
        .slick-next,.slick-prev{
           width: 34px; height: 34px; 
           transition: 0.3s ease-in-out;
           &:before{
               display: none;
           }
           @include screen(768px,1280px) {
               top: -65px; 
            }
           @include SP{
               top: calc(100% + 34px);
               z-index: 99;
           }
        }
        .slick-next{
            background: url(../../img/common/icon/ico_sli_next.svg) no-repeat center center / 100% 100% ; 
            right: -70px;
            @include PC{
                &:hover{
                    right: -75px;
                }   
            } 
            @include screen(768px,1280px) {
                right: 10px;
                &:hover{
                    right: 5px;
                }
            }
            @include SP{
                right: 14px;
               
            }
        }
        .slick-prev{
            background: url(../../img/common/icon/ico_sli_prev.svg) no-repeat center center / 100% 100% ; 
            left: -55px;
            @include PC{
                &:hover{
                    left: -60px; 
                } 
            }
            @include screen(768px,1280px) {
                left: auto;   
                right: 55px;
                &:hover{
                    right: 60px;
                    left: auto;
                }
            }
            @include SP{
                left: auto;
                right: 59px;
            }
        }
        .slick-dots{
            bottom: -65px;
            @include SP{
                text-align: left;
                padding-left: 17px;
                bottom: -55px;
            }
            li{
                width: 12px;
                height: 12px; 
                position: relative;
                margin: 0 4px;
                &:before{
                    position: absolute; content: "";
                    @include centerer();
                    width: 4px;
                   height: 4px;
                   border-radius: 50%;
                   background: #2B2626;
                }
               button{
                   width: 4px;
                   height: 4px;
                   border-radius: 50%;
                   background: #2B2626;
                   padding: 0;
                   display: none;
                   &::before{
                       
                   }
               }
               &.slick-active{
                &:before{
                    width: 12px;
                   height: 12px;
                   background: #E15B29;
                }
                   
               }
            }
        }
    }
}


.commitment{
    padding: 105px 0 100px ;
    position: relative;
    @include SP{
        padding: 44px 0 75px;
    }
    &:after{
        content: "";
        position: absolute;
        height: 34%;
        width: calc(50% - 75px);
        background: url(../../img/top/gr1_bg.svg)  repeat    ;  
        background-position: left top 7px;
        right: 0;
        top: 0;
        z-index: -1;
        @include SP{
            display: none;
        }
    }
    .wcm{
        max-width: 1170px;
    }
    .list{
        
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 25px;
        @include SP{
            padding-top: 15px;
        }
         
        li{
            width: 50%;
            padding: 30px 21px 0;
            margin-bottom: 30px;
            @include SP{
                width: 100%;
                padding: 0;
                margin-bottom: 22px;
            }
            a{
                display: inline-block;
                width: 100%;
                position: relative;
                background: #F2F2F2;
                border-radius:0  0  10px 10px; 
                height: 100%;
                @include PC{
                    display: flex;
                    flex-direction: column; 
                }
                
                @include SP{
                    display: flex;
                    flex-wrap: wrap;
                    border-radius:5px  5px 5px 5px; 
                }
                .zimg_frame{
                    border-radius: 10px 10px 0 0;
                    @include SP{
                        max-width: 130px ;
                        width: 100%;
                        border-radius: 5px 0px 0 5px;
                    }
                }
                .im{
                    @include aspect-ratio(529px,331px); 
                    overflow: hidden;
                    
                }
               .num{
                   position: absolute;
                   top: -20px;
                   left: 40px;
                   @include SP{
                       left: 13px;
                       top: -14px;
                       img{
                        height: 27px; width: auto; 
                       }
                       
                   }
               }
               .tit{
                   @include font(28,37px,0.02em,bold);
                   padding: 22px 40px 26px 40px ;
                   position: relative;
                   flex: 1 0 auto;
                   @include PC{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;  
                   }
                   
                   @include ipad{
                       font-size: 22px;
                   }
                   @include SP{
                    @include font(20,28px,0.02em,bold);
                    width: calc(100% - 130px);
                    padding: 26px 25px 26px 23px ;
                   }
                   span{
                       color: #E15B29;
                       display: inline-block;
                   }
                   &:after{
                       content: '';
                       position: absolute;
                       width: 22px; height: 11px;
                       background: url(../../img/common/icon/ico_arr_black.svg) no-repeat center center  / 100% 100%; 
                       @include centerer();
                       left: auto; right: 20px;
                       transition: 0.3s;
                       @include SP{
                           right: -3px;
                       }
                   }
               }
               @include PC{
                   &:hover{
                       .tit:after{
                           right: 15px;
                       }
                   }
               }
            }
        }
    }
}

.r-plan{
    background: #FAF4EF;
    padding: 90px 0 100px;
    position: relative;
    @include SP{
        padding: 54px 0 40px;
    }
    .gr-title .jp2{
        @include SP{
            @include font-size(28);
        }
    }
    &:after{
        content: "";
        position: absolute;
        height: 45%;
        width: calc(50% - 75px);
        background: url(../../img/top/gr1_bg.svg)  repeat    ;  
        left:  0;
        bottom: 0;
        z-index: 1;
        @include SP{
            width: 50%;
        }
    }
    .wcm{
        z-index: 2;
        position: relative;
    }
    .row{
        margin-bottom: 60px;
        padding-top: 30px;
        @include SP{
            padding-top: 0;
            margin-bottom: 35px;
        }
        &__tit{
            @include font(26,54px,0.04em,bold);
            color: #fff;
            position: relative;
            background: #31659B;
            border-radius: 5px;
            padding-left: 50px;
            width: calc(100% - 40px);
            margin-left: 40px;
            margin-bottom: 50px;
            @include SP{
                margin-bottom: 26px;
            }
            &:before{
                content: "";
                position: absolute;
                @include centerer();
                left: -9px;
                width: 70px; height: 70px; 
                border-radius: 50%;
                border: 3px solid #31659B;
                background: #fff url(../../img/top/r-plan_ico1.svg) no-repeat center center / 34px 29px ; 
                @include SP{
                    width: 60px; height: 60px;
                }
            }
            @include SP{
                @include font(20,50px,0.04em,bold);
                padding-left: 35px;
            }
        }
        &__list{
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 40px);
            margin-left: -20px;
            @include SP{
                width: 100%;
                margin-left: auto;
            }
            .item{
                width: 33.3333%;
                padding: 0 20px;
                margin-bottom: 30px;
                @include SP{
                    width: 100%;
                    margin-bottom: 13px;
                    padding: 0  ;
                }
                a{
                    background: #fff;
                    display: inline-block;
                    border-radius:   5px; 
                    width: 100%;
                    -webkit-box-shadow: 3px 3px 16px 0px rgba(69,93,118,0.16); 
                    box-shadow: 3px 3px 16px 0px rgba(69,93,118,0.16);
                    height: 100%;
                    @include SP{
                        display: flex;
                        flex-wrap: wrap;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                    .zimg_frame{
                        @include PC{
                            border-radius: 5px 5px 0 0;
                            overflow: hidden;
                        }
                        @include SP{
                            width: 150px;
                        }
                        @include IP5{
                            width: 100px;
                        }
                        .im{
                            @include aspect-ratio(340px,212px);
                        }
                    }
                    .info{
                        padding: 20px 65px 20px 25px;
                        position: relative;
                        @include SP{
                            width: calc(100% - 150px);
                            padding:  13px 30px 12px 20px ;
                            text-align: left;
                        }
                        @include IP5{
                            width: calc(100% - 100px);
                        }
                        &::after{
                            content: "";
                            @include centerer();
                            width: 20px;
                            height: 10px;
                            background: url(../../img/common/icon/ico_arr_black.svg) no-repeat center center/100% 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            left: auto;
                            right: 7px;
                            transition: 0.3s ease-in-out;
                            @include SP{
                                right: 0px;
                            }
                        }
                        &__ins{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;;
                            align-items: center;
                            .ttl{
                                @include font(16,26px,0.04em,bold);
                                position: relative;
                                @include SP{
                                    @include font(18,28px,0.04em,bold);
                                    width: 100%;
                                    margin-bottom: 5px;
                                    br{
                                        display: none;
                                    }
                                }
                                i{
                                    font-style: normal;
                                    @include PC{
                                        @include font-size(29);
                                        line-height: 1;
                                        position: absolute;
                                        left: -17px;
                                        top: 2px;
                                    }
                                    
                                }
                                &.cus {
                                    @include font-size(14);
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    @include SP{
                                        @include font-size(17);
                                    }
                                }
                            } 
                            .price{
                                color: #E15B29;
                                display: flex;
                                
                                &__p1{
                                    @include font(42,1,-0.04em,bold);
                                    @include inter;
                                    color: #E15B29;
                                    @include SP{
                                        @include font-size(32);
                                        margin-bottom: 2px;
                                    }
                                }
                                &__p2{
                                    @include font(20,1,0.04em,bold); 
                                    max-width: 67px;
                                    position: relative;
                                    text-align: center;
                                    @include SP{
                                         display: flex;
                                        flex-direction: column;
                                        text-align: center;
                                        justify-content: center;
                                    }
                                   
                                    &:after{
                                        content: "〜";  
                                        @include centerer(); 
                                        right: -22px; left: auto;
                                        @include SP{
                                            @include font-size(20);
                                            right: -28px;
                                        }
                                    }
                                    em{ 
                                        display: inline-block;
                                        font-style: normal; 
                                        &:nth-of-type(1){
                                            @include font(20,1,0.04em,bold);  
                                            @include SP{
                                                @include font-size(13);
                                                margin-bottom: 2px;
                                            }
                                        }
                                        &:nth-of-type(2){
                                            @include font(14,1,0.04em,bold); 
                                            font-style: normal;
                                            display: inline-block; 
                                            @include SP{
                                                @include font-size(9);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .note{
                            @include font(12,1.6,0,'');
                            text-align: left;
                            @include SP{
                                text-align: left;
                                @include font-size(10);
                            }
                            
                        }
                    }
                    @include PC{
                        &:hover{
                            .info::after{
                                right: 2px;
                            }
                        }
                    }
                }
                &--i11{
                    a .info{
                        @include PC{
                            padding-left: 34px ;
                        }  
                    }
                    
                }
                &--i12,&--i13{
                    a .info__ins .ttl{
                        @include PC{
                            @include font-size(18);
                        }
                    }
                }
                &--i14,&--i15{
                    a .info__ins .ttl{
                        @include PC{
                            @include font-size(20);
                        }
                    }
                }
            }
        }
        &--r2{
            padding-top: 0;
            @include PC{
                margin-bottom: 30px;
            }
            @include SP{
                margin-bottom: 30px;
            }
            .row__tit{
                background: #308A91;
                &:before{
                    background:#fff url(../../img/top/r-plan_ico2.svg) no-repeat center center / 30px 30px ; 
                    border: 3px solid #308A91; ;
                }   
            }
            .row__list{
                @include SP{
                    
                }
                .item{
                    .info{ 
                        @include PC{
                            padding: 20px 56px 20px 15px;
                        }
                        @include SP{
                            padding: 13px 24px 12px 20px;
                        }
                        &__ins{
                            .ttl{
                                
                               
                                @include PC{
                                    text-align: center;
                                    @include font-size(20);
                                    max-width: 105px;
                                    width: 100%;
                                }
                                @include SP{
                                    @include font-size(16);
                                }
                                span{
                                    @include PC{
                                        @include font-size(18); 
                                        letter-spacing: 0;
                                        &.st2{
                                            @include font-size(15);
                                            position: relative;
                                            top: -5px;
                                            letter-spacing: -0.04em;
                                        } 
                                    }
                                    
                                }
                            }  
                            .price{
                                @include PC{
                                    /*width: calc(100% - 105px);*/
                                    position: relative;
                                    left: 0px;
                                    display: flex;
                                    text-align: right;
                                    justify-content: flex-end;   
                                }
                                
                            } 
                        }    
                    }
                    &:nth-child(3){
                        a .info__ins .price__p2{
                            @include PC{
                                left: -5px;
                            }
                        }
                        .info{
                            @include PC{
                                padding-right: 40px;
                            }
                        }
                    }
                } 
            }
        }
    }
}

.works{
    padding: 130px 0 110px;
    position: relative;
    @include SP{
        padding: 40px 0 75px;
        overflow: hidden;
    }
    &:after{
        content: "";
        position: absolute;
        height: 42%;
        width: calc(50% - 75px);
        background: url(../../img/top/gr1_bg.svg)  repeat    ;  
        background-position: left top 6px;
        left:  0;
        top: 0;
        z-index: -1;
        @include SP{
            width: 50%;
        }
    }
    &__cont{
    padding: 20px 0 0 0px;   
    position: relative;
    z-index: 1;
    @include SP{
        padding:  0px 0 0 15px; 
    }
    }
    &__list{
        //max-width: 1126px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        @include SP{
             padding: 0  0px 0  0px; 
              
        }
        
        .item{
            width: 33.333%;
            padding: 0 20px;
            max-width: 376px;
            @include PC{
                min-width: 376px;
            }
            @include SP{
                max-width: 100%;
                margin: 0  0px 0  0px;
                padding: 0 ;
            }
            a{ 
                display: inline-block;
                width: 100%;
                background: #fff;
                border-radius: 0 0 5px 5px;
                text-align: left;
                padding: 0 0 15px;
                .zimg_frame{
                    border-radius: 5px 5px 5px 5px;
                    margin-bottom: 24px;
                    position: relative;
                    @include SP{
                        margin-bottom: 16px;
                    }
                }
                .zimg_bg{
                    
                }
                .im{
                    @include aspect-ratio(340px,212px); 
                    overflow: hidden;
                    span{
                        position: absolute;
                        width: 100%; height: 100%; top: 0; left: 0;
                         
                    }
                }
                 
                .cat{ 
                    margin-bottom: 10px;
                    span{
                        @include font(14,22px,0.04em,bold);
                        color: #fff;
                        background: #7c7c7c;
                        border-radius: 4px ;
                        padding: 1px 8px 2px;
                        margin-right: 5px;
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                }
                .ttl{ 
                    @include font(22,32px,0.04em,bold);
                    color: #2B2626;
                    margin-bottom: 17px;
                    transition: 0.3s ;
                    @include SP{
                        @include font(18,28px,0.04em,bold); 
                    }
                }
                .bx{
                    border-radius: 10px;
                    padding: 9px 13px 17px 21px;
                    background: #F2F2F2;
                    @include SP{
                        border-radius: 5px;
                    }
                    .ttl2{
                        @include font(18,34px,0.04em,bold);
                        transition: 0.3s ;
                        @include SP{
                            @include font(17,34px,0.04em,bold); 
                        }
                    }
                    p{
                        @include font(16,24px,0.04em,500);
                        transition: 0.3s ;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        white-space: normal;
                        overflow: hidden;
                        @include SP{
                            line-height: 22px;
                        }
                    }
                }
                @include PC{
                    &:hover{
                       .ttl{
                           color: #E15B29;
                       } 
                       .bx{
                        .ttl2{
                            color: #E15B29;
                        } 
                        p{
                            color: #E15B29;
                        }
                       }
                    }
                }
                
            }
        }
        .slick-list{
            margin-bottom: 35px; 
            @include SP{
                width: calc(100% + 40px);
                margin-bottom: 20px;
            }
            // @include SP{
            //     overflow: visible;
            // }
        }
        .slick-slide{
            @include SP{
                max-width: 100%;
                margin-right: 15px;
            }   
        }
        .slick-next,.slick-prev{
            width: 34px; height: 34px; 
            transition: 0.3s ease-in-out;
            top: -84px;
             
            @include SP{
                top: 21%;
                z-index: 99;
            }
            &:before{
                display: none;
            }
         }
         .slick-next{
             background: url(../../img/common/icon/ico_sli_next.svg) no-repeat center center / 100% 100% ; 
             right: 44px;
             right: calc((100vw - 1100px) / 2 + 10px);
             left: auto;
             @include PC{
                 &:hover{
                    right: calc((100vw - 1100px) / 2 + 5px);
                 }   
             } 
             @include screen(1024px,1280px) {
                right: 10px;
             }
             @include SP{
                right: 0px;
             }
         }
         .slick-prev{
             background: url(../../img/common/icon/ico_sli_prev.svg) no-repeat center center / 100% 100% ; 
             left: auto;
             right: calc((100vw - 1100px) / 2 + 56px);
             @include PC{
                 &:hover{
                    right: calc((100vw - 1100px) / 2 + 62px);
                 } 
             }
             @include screen(1024px,1280px) {
                left: auto;
                right: 55px ; 
            }
             @include SP{
                 left: -15px;
                
             }
         }
         .slick-dots{
            bottom: -65px;
            @include SP{ 
                bottom: -5px;
            }
            li{
                width: 12px;
                height: 12px; 
                position: relative;
                margin: 0 4px;
                &:before{
                    position: absolute; content: "";
                    @include centerer();
                    width: 4px;
                   height: 4px;
                   border-radius: 50%;
                   background: #2B2626;
                }
               button{
                   width: 4px;
                   height: 4px;
                   border-radius: 50%;
                   background: #2B2626;
                   padding: 0;
                   display: none;
                   &::before{
                       
                   }
               }
               &.slick-active{
                &:before{
                    width: 12px;
                   height: 12px;
                   background: #E15B29;
                }
                   
               }
            }
         }
    }
}

.voice{
    padding: 76px 0 100px;
    background: #FAF4EF;
    @include SP{
        padding: 56px 0 75px;
    }
    .gr-title{
        .jp{
            @include PC{
                @include font-size(38);
            }
        }
    }
    &__cont{
    padding: 12px 14px 40px;   
    max-width: 1128px;
    width: 100%;
    margin: auto;
    position: relative;
        @include SP{
             
            padding:  0px 0 0 15px;  
        }
    }
    &__list{
        
        width: calc(100% + 40px); 
        margin-left: -20px;
        display: flex;
        flex-wrap: wrap;
        @include SP{
            width: 100%;
            margin-left: auto
            
        }
        
        .item{
            width: 33.333%;
            padding: 0 20px;
            @include SP{
                max-width: 100%;
                margin: 0  0px 0  0px;
                padding: 0 0 10px 0;
            }
            
            a{ 
                display: inline-block;
                width: 100%;
                background: #fff;
                border-radius: 0 0 5px 5px;
                text-align: left;
                padding: 0 0 15px;
                -webkit-box-shadow: 3px 3px 16px 0px rgba(69,93,118,0.16); 
                box-shadow: 3px 3px 16px 0px rgba(69,93,118,0.16);
                @include SP{
                    -webkit-box-shadow: 3px 3px 10px 0px rgba(131,113,98,0.1); 
                    box-shadow: 3px 3px 10px 0px rgba(131,113,98,0.1);
                   
                }
                .zimg_frame{
                    border-radius: 5px 5px  0px 0px;
                    margin-bottom: 24px;
                    position: relative;
                    @include SP{
                        margin-bottom: 16px;
                    }
                }
                .zimg_bg{
                    
                }
                .im{
                    @include aspect-ratio(340px,212px); 
                    overflow: hidden;
                    span{
                        position: absolute;
                        width: 100%; height: 100%; top: 0; left: 0;
                         
                    }
                }
                 
                .cat{ 
                    margin-bottom: 10px;
                    padding: 0 20px;
                    @include SP{
                        padding: 0 15px;
                        margin-bottom: 3px;
                    }
                    span{
                        @include font(14,22px,0.04em,bold);
                        color: #fff;
                        background: #7c7c7c;
                        border-radius: 4px ;
                        padding: 1px 8px 2px;
                        margin-right: 5px;
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                }
                .ttl{ 
                    @include font(22,32px,0.04em,bold);
                    color: #2B2626;
                    margin-bottom: 17px;
                    transition: 0.3s ;
                    padding: 0 20px;
                    @include SP{
                        padding: 0 15px;
                        margin-bottom: 20px;
                        @include font(18,26px,0.04em,bold);
                    }
                }
                .bx{ 
                    padding: 13px 20px 10px 20px; 
                    position: relative;
                    @include SP{
                        padding: 11px 17px 5px 17px; 
                    }
                    &:before{
                        content: "";
                        position: absolute;
                        width: calc(100% - 40px);
                        height: 1px ;
                        background: #D8D8D8;
                        left: 20px; top: 0;
                    }
                    .ttl2{
                        @include font(17,34px,0.04em,bold);
                        transition: 0.3s ;
                        @include SP{
                             
                        }
                    }
                    .txt{
                        @include font(16,24px,0.04em,500);
                        transition: 0.3s ;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        white-space: normal;
                        overflow: hidden;
                    }
                }
                @include PC{
                    &:hover{
                       .ttl{
                           color: #E15B29;
                       } 
                       .bx{
                        .ttl2{
                            color: #E15B29;
                        } 
                        p{
                            color: #E15B29;
                        }
                       }
                    }
                }
            }
        }
        
    }
    .slick-list{
        margin-bottom: 35px; 
        @include SP{
            width: calc(100% + 40px);
            margin-bottom: 20px;
        }
        // @include SP{
        //     overflow: visible;
        // }
    }
    .slick-slide{
        @include SP{
            max-width: 100%;
            margin-right: 15px;
        }   
    }
    .slick-next,.slick-prev{
        width: 34px; height: 34px; 
        transition: 0.3s ease-in-out;
        top: -84px;
        @include SP{
            top: 22%;
            z-index: 99;
        }
        &:before{
            display: none;
        }
     }
     .slick-next{
         background: url(../../img/common/icon/ico_sli_next.svg) no-repeat center center / 100% 100% ; 
         right: 44px;
         right: calc((100vw - 1100px) / 2 + 10px);
         left: auto;
         @include PC{
             &:hover{
                right: calc((100vw - 1100px) / 2 + 5px);
             }   
         } 
         @include SP{
            right: 0px;
         }
     }
     .slick-prev{
         background: url(../../img/common/icon/ico_sli_prev.svg) no-repeat center center / 100% 100% ; 
         left: auto;
         right: calc((100vw - 1100px) / 2 + 56px);
         @include PC{
             &:hover{
                right: calc((100vw - 1100px) / 2 + 62px);
             } 
         }
         @include SP{
             left: -15px;
            
         }
     }
     .slick-dots{
        bottom: -65px;
        @include SP{ 
            bottom: -10px;
        }
        li{
            width: 12px;
            height: 12px; 
            position: relative;
            margin: 0 4px;
            &:before{
                position: absolute; content: "";
                @include centerer();
                width: 4px;
               height: 4px;
               border-radius: 50%;
               background: #2B2626;
            }
           button{
               width: 4px;
               height: 4px;
               border-radius: 50%;
               background: #2B2626;
               padding: 0;
               display: none;
               &::before{
                   
               }
           }
           &.slick-active{
            &:before{
                width: 12px;
               height: 12px;
               background: #E15B29;
            }
               
           }
        }
     }
}

.office{
    padding: 93px 0 100px; 
    @include SP{
        padding: 55px 0 50px;
    }
    .gr-title{
        .jp{
            @include PC{
                @include font-size(38);
            }
        }
    }
    &__cont{
        padding: 12px 14px  0px;   
        max-width: 1128px;
        width: 100%;
        margin: auto;
        position: relative;
        @include SP{
            padding-top: 5px;
            margin-bottom: -5px;
        }
    }
    &__list{
        
        width: calc(100% + 88px); 
        margin-left: -44px;
        display: flex;
        flex-wrap: wrap;
        @include SP{
            width: 100%;
            margin-left: auto;
        }
        .item{
            width: 50%;
            padding: 0 44px;
            margin-bottom: 45px;
            @include SP{
                width: 100%;
                padding: 0;
            }
            a.ins{ 
                display: inline-block;
                width: 100%;
                background: #fff;
                border-radius: 0 0 5px 5px;
                text-align: left;
                padding: 0 0 15px;
                @include SP{
                    display: flex;
                    flex-wrap: wrap; 
                }
                 
                .zimg_frame{
                    border-radius: 5px 5px  5px 5px;
                    margin-bottom: 24px;
                    position: relative;
                    @include SP{
                        width: 165px;
                        margin-bottom: 14px;
                    }
                    @include IP5{
                        width: 110px;
                    }
                }
                .zimg_bg{
                    
                }
                .im{
                    @include aspect-ratio(505px,316px); 
                    overflow: hidden;
                    span{
                        position: absolute;
                        width: 100%; height: 100%; top: 0; left: 0;
                         
                    }
                }
                 
                .cat{ 
                    margin-bottom: 10px; 
                    span{
                        @include font(14,22px,0.04em,bold);
                        color: #fff;
                        background: #7c7c7c;
                        border-radius: 3px ;
                        padding: 1px 8px 2px;
                        margin-right: 5px;
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                }
                .ttl{ 
                    @include font(22,34px,0.04em,bold);
                    color: #2B2626;
                    margin-bottom: 12px;
                    transition: 0.3s ; 
                    @include SP{
                        width: calc(100% - 165px);
                        @include font(20,28px,0.04em,bold);
                        display: flex;
                        align-items: center;
                        padding: 0 30px 0 20px; 
                        position: relative;
                        &::after{ 
                            @include SP{
                                content: "";
                                @include centerer();
                                width: 20px;
                                height: 10px;
                                background: url(../../img/common/icon/ico_arr_black.svg) no-repeat center center/100% 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                left: auto;
                                right: -14px;
                                transition: 0.3s ease-in-out;   
                            }
                        }
                    }
                    @include IP5{
                        width: calc(100% - 110px);
                        margin-top: 0;
                    }
                }
                .bx{ 
                    padding: 0px 0px 10px 0px; 
                    position: relative;  
                    @include SP{
                        width: 100%;
                        padding-bottom: 0;
                    }
                    p{
                        @include font(16,22px,0.04em,500);
                        transition: 0.3s ;
                    }
                    .add{
                        display: inline-block;
                        padding-left: 20px;
                        position: relative;
                        background: url(../../img/common/icon/icon_address.svg) left top 4px no-repeat;
                        background-size: 12px   17px; 
                        margin-bottom: 5px;
                        @include SP{
                            padding-left: 26px;
                        }
                        em{
                            font-style: normal;
                            display: inline-block;
                        }
                    }
                    .cont{
                        padding-left: 20px;
                        position: relative;
                        background: url(../../img/common/icon/icon_tel.svg) left center no-repeat;
                        background-size: 13px 13px;
                        em{
                            font-style: normal;
                            display: inline-block; 
                        }
                    }
                }
                
                @include PC{
                    &:hover{
                       .ttl{
                           color: #E15B29;
                       } 
                       .bx{
                        color: #E15B29;
                         
                        p{
                            color: #E15B29;
                        }
                       }
                    }
                }
            }
            .bx-tel{ 
                display: inline-block;
                position: relative;
                @include SP{
                  width: 100%;
                  text-align: center;
                  border: 1px solid #C3C3C3;
                  border-radius: 10px;
                  padding: 18px  0px 0;
                  line-height: 1;
                }
                &::before{
                  position: absolute;
                  content: "";
                  width: 45px; height: 45px; left: -56px; top: 0;
                  background: url(../../img/common/icon/ico_tel.svg) center center  / cover no-repeat;
                  @include SP{
                     width: 42px; height: 42px; top: 17px;
                     left: calc(50% - 127px);
                  }
                }
                .num{
                  @include font(30,24px,0.04em,700);
                  @include inter;
                  margin-bottom: 8px;
                  display: inline-block;
                  @include SP{
                    padding-left: 30px;
                    @include font(25,25px,0.04em,700);
                    margin-bottom: 2px;
                     
                  }
                }
                .add{
                  @include font(13,23px,0.1em, );
                  @include SP{
                    @include font(10,17px,0.1em, );
                    padding-left: 26px;
                    padding-bottom: 8px;
                  }
                }
                .note{
                  @include font(10,24px,0.1em, );
                  background: #FFF3DC;
                  width: 100%; display: inline-block;
                  border-radius: 0 0 10px 10px;
                }
              }
        }
         
    }
}

.blog{
    padding: 76px 0 115px; 
    position: relative;
    @include SP{
        padding: 0px 0 65px; 
    }
    &:after{
        content: "";
        position: absolute;
        height: 66%;
        width: calc(50% - 87px);
        background: url(../../img/top/gr1_bg.svg)  repeat    ;  
        right: 0;
        bottom: 0;
        z-index: -1;
        @include SP{
            display: none;
        }
    }
    .gr-title{
        .jp{
            @include PC{
                @include font-size(38);
            }
        }
    }
    &__cont{
        padding: 12px 14px 54px;   
        max-width: 1128px;
        width: 100%;
        margin: auto;
        position: relative;
        @include SP{
            padding-bottom: 35px;
        }
    }
    &__list{
        
        width: calc(100% + 40px); 
        margin-left: -20px;
        display: flex;
        flex-wrap: wrap;
        @include SP{
            width: 100%;
            margin-left: auto;
        }
        .item{
            width: 33.333%;
            padding: 0 20px;
            @include SP{
                width: 100%;
                padding: 0;
            }
            a{ 
                display: inline-block;
                width: 100%;
                background: #fff;
                border-radius: 0 0 5px 5px;
                text-align: left;
                padding: 0 0 15px;
                @include SP{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    padding-bottom: 26px;
                }
                .zimg_frame{
                    border-radius: 5px 5px  5px 5px;
                    margin-bottom: 24px;
                    position: relative;
                    @include SP{
                        width: 140px;
                    }
                    @include IP5{
                        width: 110px;
                    }
                }
                .zimg_bg{
                    
                }
                .im{
                    @include aspect-ratio(340px,212px); 
                    overflow: hidden;
                    span{
                        position: absolute;
                        width: 100%; height: 100%; top: 0; left: 0;
                         
                    }
                }
                
                .bx{
                    @include SP{
                        width: calc(100% - 140px);
                        padding-left: 20px;
                    }
                    @include IP5{
                        width: calc(100% - 110px);
                    }
                }
                .cat{ 
                    margin-bottom: 4px; 
                    span{
                        @include font(14,22px,0.04em,bold);
                        color: #fff;
                        background: #7c7c7c;
                        border-radius: 5px ;
                        padding: 1px 8px 2px;
                        margin-right: 5px;
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                }
                .ttl{ 
                    @include font(20,32px,0.04em,bold);
                    color: #2B2626;
                    margin-bottom: 13px;
                    transition: 0.3s ;
                    @include SP{
                        @include font(18,26px,0.04em,bold);
                        margin-bottom: 5px;
                    }
                }
                .date{
                    @include font(12,22px,0.04em,500);
                    color: #7C7C7C;
                }
               
                @include PC{
                    &:hover{
                       .ttl{
                           color: #E15B29;
                       } 
                       .bx{
                        .ttl2{
                            color: #E15B29;
                        } 
                        p{
                            color: #E15B29;
                        }
                       }
                    }
                }
            }
        }
         
    }
}

.box-text{
    background: url(../../img/top/box-text_bg.jpg)  no-repeat center center / cover   ;   
    @include SP{
        background: url(../../img/top/box-text_bg_sp.jpg)  no-repeat center center / cover   ;   
    }
    .gr-txt{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 70px 0 82px;
        @include SP{
            padding: 50px 0 10px;
        }
        .it{
            width: calc(50% - 22px);
            @include SP{
                width: 100%;
                margin-bottom: 55px;
            }
             .ttl{
                 @include font(22,1.6,0.04em,bold);
                 margin-bottom: 20px;;
                 @include SP{
                     line-height: 24px;
                     margin-bottom: 25px;
                 }
             }
             p{
                @include font(16,26px,0.04em,'');
                @include SP{
                    @include font(14,24px,0.04em,'');
                }
             }
        }
    }
}

#footer{
   .bx-contact{
        background: #308A91;
   }
}